import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Table,
	Spin,
	Tag,
	Collapse,
	Pagination,
	Select,
	Form,
	Typography,
	Image,
} from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
import { ETrend, NOrganizationMulti } from "../../../models/contants";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";
import defaultLogo from "../../../assets/images/company/defaul-logo_organization.png";
import { useHistory } from "react-router-dom";
import imgSource from "../../../assets";
import "../index2.less";
import TrendNotFound from "./TrendNotFound";
const { Option } = Select;
const { Text } = Typography;

export default (props) => {
	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const history = useHistory();
	const { trendingOrganizations, loading } = useStore(NOrganizationMulti);
	const [searchValue, setSearchValue] = useState(null);
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	function GamesColumn(text, record, index) {
		const showAll = showAllTags[index] || false;
		const games = record?.withGamesList ? [...record?.withGamesList] : [];
		const showCollapse = games?.length > 2;

		return (
			<div>
				{games
					?.slice(0, showAll ? record.withGamesList.length : 3)
					?.map((game, i) => (
						<Tag
							onClick={() => {
								toDetails(game?.id, true);
							}}
							className="greenTag"
							key={i}
							style={{ marginBottom: 4 }}
						>
							{game?.gameEn}
						</Tag>
					))}
				{(record?.withGamesList?.length > 3 ||
					record?.withCompanys?.length > 3 ||
					record?.withTeams?.length > 3 ||
					record?.withPlatforms?.length > 3 ||
					record?.genres?.length > 3) && (
					<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
						{showAll ? "Show less..." : "Show all..."}
					</Tag>
				)}
			</div>
		);
	}
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};

	useEffect(() => {
		effect(NOrganizationMulti, ETrend, { page: 1, pageSize: 10 });
	}, []);

	const trendFilter = (value) => {
		effect(NOrganizationMulti, ETrend, {
			page: 1,
			pageSize: 10,
			trendFilter: value,
		});
	};

	const [showAllTags, setShowAllTags] = useState({});
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const columns = [
		{
			title: "Organization",
			dataIndex: "name",
			key: "name",
			width: 300,
			align: "left",
			sortOrder: sortState?.field == "name" && sortState?.order,
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a
									className="a_style"
									key={record?.id}
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.name}
								</a>
							</p>
							{record?.localName && (
								<p>
									<a
										className="a_style"
										onClick={() => {
											toDetails(record?.id);
										}}
									>
										{record?.localName}
									</a>
								</p>
							)}
						</div>
					</div>
				</>
			),
		},

		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			width: 150,
			align: "left",
			sortOrder: sortState?.field == "type" && sortState?.order,
			sorter: (a, b) => {
				const typeA = a?.type?.[0] || "";
				const typeB = b?.type?.[0] || "";
				return typeA?.localeCompare(typeB);
			},
			render: (text, value, index) => (
				<>
					{value?.type?.map((item, index) => {
						return <div key={index}>{item + "\n"}</div>;
					})}
				</>
			),
		},
		{
			title: "HQ Location",
			dataIndex: "area",
			key: "area",
			width: 150,
			align: "left",
			sortOrder: sortState?.field == "area" && sortState?.order,
			sorter: (a, b) => {
				const areaA = a?.area?.[0] || "";
				const areaB = b?.area?.[0] || "";
				return areaA?.localeCompare(areaB);
			},
			render: (text, record) => (
				<div>
					{record?.area?.length
						? record?.withArea?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			width: 150,
			align: "left",
			sortOrder: sortState?.field == "region" && sortState?.order,
			sorter: (a, b) => {
				const regionA = a?.region?.[0] || "";
				const regionB = b?.region?.[0] || "";
				return regionA?.localeCompare(regionB);
			},
			render: (text, record) => (
				<div>
					{record?.region?.length
						? record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Information",
			dataIndex: "Information",
			key: "Information",
			width: 150,
			align: "left",
			render: (text, record, index) => {
				return (
					<div className="Tabel_Information">
						{record?.exclusives_count >= 1 && (
							// record?.skipExclusives
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.exclusives_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.exclusives_count >= 1) {
										history.push({
											pathname: "/niko_knowledge_briefs",
											state: { company: record?.id },
										});
									}
								}}
							>
								Knowledge Brief
							</a>
						)}

						{record?.insightFeed_count >= 1 && (
							// record?.skipInsightFeed
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.insightFeed_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.insightFeed_count >= 1) {
										history.push({
											pathname: "/news_articles/VANA",
											state: { company: record?.id },
										});
									}
								}}
							>
								VANA
							</a>
						)}

						{record?.tournament_count >= 1 && (
							<a
								style={
									record?.tournament_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.tournament_count >= 1) {
										history.push({
											pathname: "/esports",
											state: { hosts: record?.id },
										});
									}
								}}
							>
								Tournaments
							</a>
						)}

						{record?.blogs_count >= 1 && (
							<a
								style={
									record?.blogs_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.blogs_count >= 1) {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: { company: record?.id },
										});
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
									}
								}}
							>
								Blog Posts
							</a>
						)}
					</div>
				);
			},
		},
		{
			title: "% Change",
			dataIndex: "percentChange",
			showSorterTooltip: false,
			key: "percentChange",
			width: 300,
			align: "left",
			sortOrder: sortState?.field == "percentChange" && sortState?.order,
			// sorter: (a, b) => a?.percentChange?.localeCompare(b?.percentChange),
			render: (text, record, index) => (
				<>
					{record?.percentChange > 0 && (
						<div style={{ color: "#f4791f" }}>
							{record?.percentChange}%{" "}
							{record?.percentChange == 100 && "(New mention)"}
						</div>
					)}
				</>
			),
		},
	];

	const toDetails = (value, isGame = false) => {
		if (isGame) {
			history.push(`/games/details/${value}`);
		} else {
			history.push(`/organizations/details/${value}`);
		}
	};

	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "5rem",
						flexDirection: isMobile ? "column" : "row",
					}}
				>
					<p className="trendHeader">Trending Organizations</p>
					<div>
						<Select
							showArrow
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							placeholder={"Select"}
							onChange={trendFilter}
							className="homeSelect"
							defaultValue={"week"}
						>
							<Option value="week">Week-over-Week (Last 7 days)</Option>
							<Option value="month">Month-over-Month (Last 30 days)</Option>
							<Option value="year">Year-over-Year (Last 365 days)</Option>
						</Select>
						{trendingOrganizations?.data?.length < 10 &&
							trendingOrganizations?.data?.length != 0 && (
								<p className="textOnly">
									Only {trendingOrganizations?.data?.length} organizations
									experienced significant changes in mentions.
								</p>
							)}
					</div>
				</div>

				<Spin spinning={loading} size="large">
					<div className="CompanyBox">
						<Row className="RowBox mt30" justify="right">
							<Col span={24}>
								{trendingOrganizations?.data?.length == 0 ? (
									<TrendNotFound module="org" />
								) : !isMobile ? (
									<Table
										showSorterTooltip={{
											target: "sorter-icon",
										}}
										scroll={{ x: "max-content" }}
										dataSource={trendingOrganizations?.data || []}
										columns={columns}
										className="CompanyTableBox"
										pagination={false}
										onChange={(pagination, filters, sort) => {
											setSortState(sort);
										}}
									/>
								) : (
									<Collapse
										accordion
										activeKey={activeKey}
										onChange={handleCollapseChange}
										className="MobileList"
										expandIconPosition="right"
										expandIcon={({ isActive }) => (
											<div className="custom-arrow">
												<img
													src={isActive ? reverseArrow : iconArrow}
													alt="arrow"
												/>
											</div>
										)}
									>
										{trendingOrganizations?.data.map((record) => (
											<Panel
												header={
													<div className="panel-header">
														<div
															style={{ display: "flex", alignItems: "center" }}
														>
															{
																<img
																	src={record?.logo ? record.logo : defaultLogo} // Assuming item has an imageSrc property
																	alt="tournament"
																	className="tournament-image"
																/>
															}
															<a
																className="a_style"
																key={record?.id}
																onClick={() => {
																	toDetails(record?.id);
																}}
															>
																<span>
																	{" "}
																	{record?.name && <div>{record?.name}</div>}
																	{record?.localName && (
																		<div>{record?.localName}</div>
																	)}
																</span>
															</a>
														</div>
													</div>
												}
												key={record.id}
											>
												<p>
													<Row>
														<Col className="headCollapse" span={12}>
															Category
														</Col>
														<Col className="textCollapse" span={12}>
															{record?.category?.length
																? record?.category
																: "-"}
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Type
														</Col>
														<Col span={12}>
															{record?.type?.length ? record?.type : "-"}
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															HQ Location
														</Col>
														<Col className="textCollapse" span={12}>
															<div>
																{record?.area?.length
																	? record?.withArea?.map((vo, io) => (
																			<div key={io}>{vo}</div>
																	  ))
																	: "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Region
														</Col>
														<Col className="textCollapse" span={12}>
															<div>
																{record?.region?.length
																	? record?.region?.map((vo, io) => (
																			<div key={io}>{vo}</div>
																	  ))
																	: "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Related Games
														</Col>
														<Col className="textCollapse" span={12}>
															{GamesColumn("", record)}
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Basic Organization
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																<div>
																	{record?.isBasic == 0
																		? "No"
																		: record?.isBasic == 1
																		? "Yes"
																		: ""}
																</div>
															</>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Information
														</Col>
														<Col className="textCollapse" span={12}>
															<div className="Tabel_Information">
																{record?.exclusives_count >= 1 && (
																	<a
																		style={
																			record?.exclusives_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.exclusives_count >= 1) {
																				history.push({
																					pathname: "/niko_knowledge_briefs",
																					state: { company: record?.id },
																				});
																			}
																		}}
																	>
																		Knowledge Brief
																	</a>
																)}

																{record?.insightFeed_count >= 1 && (
																	<a
																		style={
																			record?.insightFeed_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.insightFeed_count >= 1) {
																				history.push({
																					pathname: "/news_articles/VANA",
																					state: { company: record?.id },
																				});
																			}
																		}}
																	>
																		VANA
																	</a>
																)}

																{record?.tournament_count >= 1 && (
																	<a
																		style={
																			record?.tournament_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.tournament_count >= 1) {
																				history.push({
																					pathname: "/esports",
																					state: { hosts: record?.id },
																				});
																			}
																		}}
																	>
																		Tournaments
																	</a>
																)}

																{record?.blogs_count >= 1 && (
																	<a
																		style={
																			record?.blogs_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.blogs_count >= 1) {
																				history.push({
																					pathname: "/news_articles/blog_posts",
																					state: { company: record?.id },
																				});
																				window.scrollTo({
																					top: 0,
																					behavior: "smooth",
																				});
																			}
																		}}
																	>
																		Blog Posts
																	</a>
																)}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															% Change
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																<div className="Tabel_Company">
																	<span style={{ color: "#f4791f" }}>
																		{record?.percentChange}%{" "}
																		{record?.percentChange == 100 &&
																			"(New mention)"}
																	</span>
																</div>
															</>
														</Col>
													</Row>
												</p>
											</Panel>
										))}
									</Collapse>
								)}
							</Col>
						</Row>
						{/* <Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
							<Col span={24}>
								{trendingOrganizations?.error != 1009 && trendingOrganizations?.total
									? trendingOrganizations?.total && (
											<Pagination
												current={parseInt(trendingOrganizations && trendingOrganizations?.page) || 1}
												total={(trendingOrganizations && trendingOrganizations?.total) || 0}
												pageSize={trendingOrganizations && trendingOrganizations?.perPage}
												onChange={onPageChange}
												showSizeChanger
												showQuickJumper
												showTotal={(total, range) =>
													`${range[0]}-${range[1]} of ${total} items`
												}
												locale={paginationLocale}
												itemRender={(page, type, originalElement) => {
													if (type === "page") {
														return <span>{page}</span>;
													}
													return originalElement;
												}}
												className="custom-pagination"
											/>
									  )
									: ""}
							</Col>
						</Row> */}
					</div>
				</Spin>
			</>
		</div>
	);
};
