import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import iconFilter from "@/assets/icon/icon_search.png";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Select,
	Spin,
	Tag,
	Collapse,
	Pagination,
	Typography,
	Input,
} from "antd";
import CustomModal from "../../../components/modal";
import sortData from "../../../utils/sorter";
import { effect, useStore, reducer } from "../../../utils/dva16";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
import {
	EGet,
	EGetArea,
	EGetChart,
	EGetCompany,
	EGetOne,
	EGetType,
	EGetCommonArea,
	NCompany,
	NExchange,
	NOrganizationMulti,
	NOrganizationMultiType,
	NRegion,
	RSetState,
	NCommon,
	EGetActivetType,
	NLogin,
	EGetRegion,
	NGame,
	EGeTPage,
} from "../../../models/contants";
import { Pie, G2 } from "@ant-design/charts";
import Reaulatory3 from "../../../assets/menu/Regulatory3.png";
import E from "../../../models/E";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import defaultLogo from "../../../assets/images/company/defaul-logo_organization.png";
import CustomEmpty from "@/utils/CustomEmpty";
import Organization from "./../../../models/OrganizationMulti";
const { Option } = Select;
export default (props) => {
	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const { history } = props;
	const [form] = Form.useForm();
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const [filterValues, setFilterValues] = useState([]);
	const {
		OrganizationMultiList,
		OrganizationMultiChart,
		loading,
		OrganizationCompany,
	} = useStore(NOrganizationMulti);
	const { gameSearch } = useStore(NGame);
	const [searchValue, setSearchValue] = useState(null);
	const { OrganizationMultiTypeList } = useStore(NOrganizationMultiType);
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};

	const { Text } = Typography;
	function GamesColumn(text, record, index) {
		const showAll = showAllTags[index] || false;
		const games = record?.withGamesList ? [...record?.withGamesList] : [];
		const showCollapse = games?.length > 2;
		const defaultActiveKey = showCollapse ? ["2"] : [];
		// console.log(record, "deded");
		return (
			<div>
				{games
					?.slice(0, showAll ? record.withGamesList.length : 10)
					?.map((game, i) => (
						<Tag
							onClick={() => {
								toDetails(game?.id, true);
							}}
							className="greenTag"
							key={i}
							style={{ marginBottom: 4 }}
						>
							{game?.gameEn}
						</Tag>
					))}
				{(record?.withGamesList?.length > 10 ||
					record?.withCompanys?.length > 10 ||
					record?.withTeams?.length > 10 ||
					record?.withPlatforms?.length > 10 ||
					record?.genres?.length > 10) && (
					<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
						{showAll ? "Show less..." : "Show all..."}
					</Tag>
				)}
				{/* {showCollapse && (
					<>
						<Collapse
							defaultActiveKey={defaultActiveKey}
							ghost
							expandIconPosition="right"
							expandIcon={({ isActive }) =>
								isActive ? <UpOutlined /> : <DownOutlined />
							}
						>
							<Collapse.Panel key="all" className="collapse-panel">
								{games?.slice(2)?.map((game, i) => (
									<Tag
										onClick={() => {
											toDetails(game?.id, true);
										}}
										className="greenTag"
										// color={
										// 	record?.withGamesList?.includes(game) ? "green" : "cyan"
										// }
										key={i}
										style={{ marginBottom: 4 }}
									>
										{game?.gameEn}
									</Tag>
								))}
							</Collapse.Panel>
						</Collapse>
					</>
				)} */}
			</div>
		);
	}
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	useEffect(() => {
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NCommon, EGetActivetType, { page: 1, perPage: 100 });
		effect(NExchange, EGet, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetCommonArea);
		effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10 });
		effect(NOrganizationMulti, EGetChart, {
			category: ["Company", "Regulatory", "Government"],
		});
		return () => {
			reducer(NCompany, RSetState, { companyChartData: null });
		};
	}, []);
	useEffect(() => {
		let page = sessionStorage.getItem("orgPage");
		let pageSize = sessionStorage.getItem("orgPageSize");
		if (page && pageSize) {
			effect(NOrganizationMulti, EGet, { page, pageSize });
		} else {
			effect(NOrganizationMulti, EGet, { page: 1, pageSize: 10 });
		}
	}, []);

	const CompanyCharts = () => {
		const G = G2.getEngine("canvas");
		const [config, setConfig] = useState({
			data: [],
			height: 300,
			appendPadding: 10,
			angleField: "value",
			colorField: "region",
			radius: 0.75,
			innerRadius: 0.6,
			forceFit: true,
			legend: {
				layout: "horizontal",
				position: "bottom",
			},
			statistic: {
				title: false,
				content: false,
			},
			label: {
				type: "spider",
				labelHeight: 40,
				style: {
					fontSize: 20,
				},
				formatter: (data, mappingData) => {
					const group = new G.Group({});
					group?.addShape({
						type: "circle",
						attrs: {
							x: 0,
							y: 0,
							width: 40,
							height: 50,
							r: 5,
							fill: mappingData?.color,
						},
					});
					group?.addShape({
						type: "text",
						attrs: {
							x: 10,
							y: 8,
							text: `${data?.region}`,
							fill: mappingData?.color,
						},
					});
					group?.addShape({
						type: "text",
						attrs: {
							x: 0,
							y: 25,
							text: `${data?.value} [${(data?.percent * 100)?.toFixed(2)}%]`,
							fill: "rgba(0, 0, 0, 0.65)",
							fontWeight: 700,
						},
					});
					return group;
				},
			},
			interactions: [
				{
					type: "element-selected",
				},
				{
					type: "element-active",
				},
			],
		});
		useEffect(() => {
			if (OrganizationMultiChart && OrganizationMultiChart?.data) {
				let arr = OrganizationMultiChart?.data?.map((v) => {
					if (v?.region == null) {
						v.region = "Others";
					}
					return {
						region: v?.region,
						value: v?.count,
					};
				});

				config.data = arr;
				setConfig(Object.assign({}, config));
			}
		}, [OrganizationMultiChart]);
		return <Pie {...config} />;
	};
	const [showAllTags, setShowAllTags] = useState({});
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const columns = [
		{
			title: "Organization",
			dataIndex: "name",
			showSorterTooltip: false,
			key: "name",
			width: 250,
			align: "left",
			sortOrder: sortState?.field == "name" && sortState?.order,
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a
									className="a_style"
									key={record?.id}
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.name}
								</a>
							</p>
							<p>
								<a
									className="a_style"
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.localName}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			width: 150,
			align: "left",
			render: (text, value, index) => <>{text ? text : "-"}</>,
			sortOrder: sortState?.field == "category" && sortState?.order,
			sorter: (a, b) => a?.category?.localeCompare(b?.category),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			align: "left",
			width: 200,
			sortOrder: sortState?.field == "type" && sortState?.order,
			sorter: (a, b) => {
				a?.type?.[0] &&
					b?.type?.[0] &&
					a?.type?.[0]?.localeCompare(b?.type?.[0]);
			},
			render: (text, value, index) => (
				<>
					{value?.type?.map((item, index) => {
						return <div key={index}>{item + "\n"}</div>;
					})}
				</>
			),
		},
		{
			title: "HQ Location",
			dataIndex: "area",
			width: 150,
			key: "area",
			align: "left",
			sortOrder: sortState?.field == "area" && sortState?.order,
			sorter: (a, b) => {
				a?.area?.[0] &&
					b?.area?.[0] &&
					a?.area?.[0]?.localeCompare(b?.area?.[0]);
			},
			render: (text, record) => (
				<div>
					{record?.area?.length
						? record?.withArea?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			width: 150,
			align: "left",
			sortOrder: sortState?.field == "region" && sortState?.order,
			sorter: (a, b) => {
				a?.region?.[0] &&
					b?.region?.[0] &&
					a?.region?.[0]?.localeCompare(b?.region?.[0]);
			},
			render: (text, record) => (
				<div>
					{record?.region?.length
						? record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Related Games",
			key: "relatedGames",
			dataIndex: "relatedGames",
			align: "center",
			className: "custom-publisher-column",
			width: 200,
			render: GamesColumn,
			sorter: true,
			sortOrder: sortState?.field == "relatedGames" && sortState?.order,
		},
		{
			title: "Basic Organization",
			key: "isBasic",
			dataIndex: "isBasic",
			align: "left",
			width: 150,
			sorter: true,
			sortOrder: sortState?.field == "isBasic" && sortState?.order,
			render: (text, record) => (
				<>
					<div>{text == 0 ? "No" : text == 1 ? "Yes" : ""}</div>
				</>
			),
		},
		{
			title: "Information",
			dataIndex: "Information",
			key: "Information",
			width: 200,
			align: "left",
			render: (text, record, index) => {
				return (
					<div className="Tabel_Information">
						{record?.__meta__?.exclusives_count >= 1 && (
							// record?.skipExclusives
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.__meta__?.exclusives_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.exclusives_count >= 1) {
										history.push({
											pathname: "/niko_knowledge_briefs",
											state: { company: record?.id },
										});
									}
								}}
							>
								Knowledge Brief
							</a>
						)}

						{record?.__meta__?.InsightFeed_count >= 1 && (
							// record?.skipInsightFeed
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.__meta__?.InsightFeed_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.InsightFeed_count >= 1) {
										history.push({
											pathname: "/news_articles/VANA",
											state: { company: record?.id },
										});
									}
								}}
							>
								VANA
							</a>
						)}

						{record?.__meta__?.Tournament_count >= 1 && (
							<a
								style={
									record?.__meta__?.Tournament_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.Tournament_count >= 1) {
										history.push({
											pathname: "/esports",
											state: { sponsor: record?.id },
										});
									}
								}}
							>
								Tournaments
							</a>
						)}

						{record?.skipTeams?.length >= 1 && (
							<a
								style={
									record?.skipTeams?.length >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.skipTeams?.length >= 1) {
										history.push({
											pathname: "/Esports/Teams",
											state: { sponsor: record?.id },
										});
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
									}
								}}
							>
								Teams
							</a>
						)}

						{record?.skipIpo?.length >= 1 && (
							<a
								style={
									record?.skipIpo?.length >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.skipIpo?.length >= 1) {
										history.push({
											pathname: "/Financial/Transactions",
											state: { companyId: record?.id },
										});
									}
								}}
							>
								M&A
							</a>
						)}
					</div>
				);
			},
		},
	];

	const toDetails = (value, isGame = false) => {
		if (isGame) {
			history.push(`/games/details/${value}`);
		} else {
			history.push(`/organizations/details/${value}`);
		}
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		values.areas = values.areas && [
			...values?.areas?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.areas,
		];
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		setFilterValues(valuesArray);
		setSearchValue(values);
		closeModal();
		effect(NOrganizationMulti, EGet, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchType = (value) => {
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10, name: value });
	};
	const onReset = () => {
		setSearchValue();
		setFormValues([]);
		sessionStorage.setItem("orgPage", 1);
		sessionStorage.setItem("orgPageSize", 10);
		form.resetFields();
		closeModal();
		effect(NOrganizationMulti, EGet, {
			page: 1,
			perPage: 10,
		});
	};

	function findRegionType(arr) {
		const updatedRegionArr = [];
		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			regions: Array.from(new Set(updatedRegionArr)),
		});
		setFormValues((prevValues) => ({
			...prevValues,
			["regions"]: Array.from(new Set(updatedRegionArr)),
		}));
	}
	function onSearchGame(value) {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	}

	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "areas") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	const paginationLocale = {
		items_per_page: "per page",
	};
	const onPageChange = (page, pageSize, sort) => {
		if (page && pageSize) {
			effect(NOrganizationMulti, EGet, {
				...searchValue,
				page: page,
				perPage: pageSize,
				field: sortState && sortState?.field,
				isDesc: (sortState && sortState?.order) || null,
			});
			sessionStorage.setItem("orgPage", page);
			sessionStorage.setItem("orgPageSize", pageSize);
		}
	};
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<>
				{/* <div className="CompanyBox mt50" style={{ backgroundColor: "#F7F9FB" }}>
					<Row gutter={24} justify="center">
						<Col span={7}>
							<div className="Statistic_Box">
								<div className="Statistic_Card">
									<div className="Statistic_Card_Sub">
										<p>Total Organizations</p>
										<h2>
											{OrganizationMultiChart?.count
												?.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										</h2>
									</div>
								</div>
							</div>
						</Col>
						<Col span={10}>
							<div className="CompanyChartBox" style={{ paddingBottom: 50 }}>
								<h3 style={{ paddingTop: 10, textAlign: "center" }}>
									Organizations by Region
								</h3>
								{CompanyCharts()}
							</div>
						</Col>
					</Row>
				</div> */}
				<div>
					<p className="mainheader">Organizations</p>
					<div className="subHeader">
						Filter the knowledge base for Organization data.
					</div>
				</div>
				<div className="compBox">
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Form
							onValuesChange={handleValuesChange}
							form={form}
							onFinish={onFinish}
							className="searchFormCard"
						>
							{" "}
							<Row
								style={{
									display: "flex",
									// justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<Form.Item
									name="company"
									labelCol={{ span: 24 }}
									className="inputBox"
								>
									<Input
										className="customInputBlog"
										placeholder="Search by Keyword"
									/>
								</Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									className="applySearchBtn"
								>
									<img src={iconFilter} />
								</Button>
							</Row>
						</Form>
					</div>
					<CustomModal
						filterValues={filterValues}
						isModalOpen={isModalOpen}
						showModal={showModal}
						closeModal={closeModal}
						content={
							<>
								{" "}
								<Form
									onValuesChange={handleValuesChange}
									name="advanced_search"
									className="ant-advanced-search-form"
									labelCol={{ span: 6 }}
									form={form}
									onFinish={onFinish}
								>
									<Row gutter={24}>
										<Col span={24}>
											<Form.Item
												name="company"
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															ORGANIZATION
														</Text>
														<Button
															disabled={
																!formValues.company ||
																formValues.company.length === 0
															}
															className={
																!formValues.company ||
																formValues.company.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("company")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
												rules={[
													{
														required: false,
														message: "Please input",
													},
												]}
											>
												{/* <Select
													showArrow
													getPopupContainer={(triggerNode) =>
														triggerNode?.parentNode
													}
													showSearch
													filterOption={false}
													placeholder="Select"
													onSearch={debounce((value) => {
														onSearchCompany(value);
													}, 300)}
												>
													{sortData(OrganizationCompany?.data)?.map(
														(item, index) => {
															return (
																<Option key={index} value={item?.name}>
																	{item?.name}
																</Option>
															);
														}
													)}
												</Select> */}
												<Input
													className="customInputBlog"
													placeholder="Input here"
												/>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												name="category"
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															CATEGORY
														</Text>
														<Button
															disabled={
																!formValues.category ||
																formValues.category.length === 0
															}
															className={
																!formValues.category ||
																formValues.category.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("category")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
											>
												<Select
													showArrow
													getPopupContainer={(triggerNode) =>
														triggerNode.parentNode
													}
													placeholder={"Select"}
												>
													<Option value="Company">Company</Option>
													<Option value="Government">Government</Option>
													<Option value="Regulatory">Regulatory</Option>
												</Select>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															TYPE
														</Text>
														<Button
															disabled={
																!formValues.types ||
																formValues.types.length === 0
															}
															className={
																!formValues.types ||
																formValues.types.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("types")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
												name="types"
											>
												<Select
													showArrow
													getPopupContainer={(triggerNode) =>
														triggerNode?.parentNode
													}
													showSearch
													filterOption={false}
													placeholder="Select"
													mode="multiple"
													onSearch={debounce((value) => {
														onSearchType(value);
													}, 300)}
												>
													{sortData(OrganizationMultiTypeList)?.map(
														(item, index) => {
															return (
																<Option key={index} value={item?.name}>
																	{item?.name}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															HQ LOCATION
														</Text>
														<Button
															disabled={
																!formValues.areas ||
																formValues.areas.length === 0
															}
															className={
																!formValues.areas ||
																formValues.areas.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("areas")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
												name="areas"
											>
												<Select
													showArrow
													getPopupContainer={(triggerNode) =>
														triggerNode?.parentNode
													}
													mode="multiple"
													placeholder="Select"
													showSearch
													onChange={(e) => {
														findRegionType(e);
													}}
													onSearch={debounce((value) => {
														const { page, perPage } = areaList;
														effect(NRegion, EGetArea, {
															page,
															perPage,
															area: value,
														});
													}, 300)}
													filterOption={false}
												>
													{sortData(areaList?.data, "area")?.map(
														(record, index) => {
															return (
																<Option
																	key={record?.id}
																	value={record?.otherData}
																>
																	{record?.otherData}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															REGION
														</Text>
														<Button
															disabled={
																!formValues.regions ||
																formValues.regions.length === 0
															}
															className={
																!formValues.regions ||
																formValues.regions.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("regions")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
												name="regions"
											>
												<Select
													showArrow
													mode="multiple"
													getPopupContainer={(triggerNode) =>
														triggerNode?.parentNode
													}
													placeholder="Select"
													filterOption={(input, option) =>
														option?.children
															?.toLowerCase()
															?.indexOf(input?.toLowerCase()) >= 0
													}
												>
													{sortData(regionList, "region")?.map((vo, index) => (
														<Option key={index} value={vo?.lookupValue}>
															{vo?.lookupValue}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>

										<Col span={24}>
											<Form.Item
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															RELATED GAME
														</Text>
														<Button
															disabled={
																!formValues.relatedGames ||
																formValues.relatedGames.length === 0
															}
															className={
																!formValues.relatedGames ||
																formValues.relatedGames.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("relatedGames")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
												name="relatedGames"
											>
												<Select
													showArrow
													showSearch
													getPopupContainer={(triggerNode) =>
														triggerNode.parentNode
													}
													placeholder="Select"
													mode="multiple"
													onSearch={debounce((value) => {
														onSearchGame(value);
													}, 200)}
													filterOption={false}
												>
													{sortData(gameSearch?.data, "gameEn")?.map(
														(record, index) => {
															return (
																<Option key={index} value={record?.id}>
																	{record?.gameEn}
																</Option>
															);
														}
													)}
												</Select>
											</Form.Item>
										</Col>
										<Col span={24}>
											<Form.Item
												labelCol={{ span: 24 }}
												label={
													<div className="headBox">
														<Text className="label" type="secondary">
															BASIC ORGANIZATION
														</Text>
														<Button
															disabled={
																!formValues.isBasic ||
																formValues.isBasic.length === 0
															}
															className={
																!formValues.isBasic ||
																formValues.isBasic.length === 0
																	? "resetDisabled"
																	: "reset"
															}
															onClick={() => resetField("isBasic")}
															type="secondary"
															style={{
																cursor: "pointer",
															}}
														>
															Reset
														</Button>
													</div>
												}
												name="isBasic"
											>
												<Select
													showArrow
													placeholder="Select "
													getPopupContainer={(triggerNode) =>
														triggerNode.parentNode
													}
												>
													<Option value={"1"}>Yes</Option>
													<Option value={"0"}>No</Option>
												</Select>
											</Form.Item>
										</Col>

										<Col span={24} style={{ textAlign: "right" }}>
											<Space>
												<Button
													type="primary"
													htmlType="submit"
													className="applyBtn"
												>
													Apply Now
												</Button>
												<Button
													type="default"
													onClick={onReset}
													className="resetBtn"
												>
													Reset All
												</Button>
											</Space>
										</Col>
									</Row>
								</Form>
							</>
						}
					/>
				</div>
				<Spin spinning={loading} size="large">
					<div className="CompanyBox">
						<Row className="RowBox mt30" justify="right">
							<Col span={24}>
								{OrganizationMultiList?.error == 1009 ? (
									customizeRenderEmpty()
								) : !isMobile ? (
									<Table
										showSorterTooltip={false}
										scroll={{ x: "max-content" }}
										dataSource={OrganizationMultiList?.data || []}
										columns={columns}
										className="CompanyTableBox"
										pagination={false}
										onChange={(pagination, filters, sort) => {
											const pageSize = OrganizationMultiList?.perPage || 10; // Get the pageSize from your data or default
											const currentPage = OrganizationMultiList?.page || 1; // Get the
											setSortState(sort);
											effect(NOrganizationMulti, EGet, {
												...searchValue,
												perPage: pageSize,
												page: currentPage,
												field: sort?.column && sort?.field,
												isDesc:
													(sort?.column &&
														sort?.order &&
														sortType[sort?.order]) ||
													null,
											});
											sessionStorage.setItem("orgPage", pagination?.page);
											sessionStorage.setItem(
												"orgPageSize",
												pagination?.pageSize
											);
										}}
									/>
								) : (
									<Collapse
										accordion
										activeKey={activeKey}
										onChange={handleCollapseChange}
										className="MobileList"
										expandIconPosition="right"
										expandIcon={({ isActive }) => (
											<div className="custom-arrow">
												<img
													src={isActive ? reverseArrow : iconArrow}
													alt="arrow"
												/>
											</div>
										)}
									>
										{OrganizationMultiList?.data.map((record) => (
											<Panel
												header={
													<div className="panel-header">
														<div
															style={{ display: "flex", alignItems: "center" }}
														>
															{
																<img
																	src={record?.logo ? record.logo : defaultLogo} // Assuming item has an imageSrc property
																	alt="tournament"
																	className="tournament-image"
																/>
															}
															<a
																className="a_style"
																key={record?.id}
																onClick={() => {
																	toDetails(record?.id);
																}}
															>
																<span>
																	{" "}
																	{record?.name && <div>{record?.name}</div>}
																	{record?.localName && (
																		<div>{record?.localName}</div>
																	)}
																</span>
															</a>
														</div>
													</div>
												}
												key={record.id}
											>
												<p>
													<Row>
														<Col className="head" span={12}>
															Category
														</Col>
														<Col className="text" span={12}>
															{record?.category?.length
																? record?.category
																: "-"}
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Type
														</Col>
														<Col span={12}>
															{record?.type?.length ? record?.type : "-"}
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															HQ Location
														</Col>
														<Col className="text" span={12}>
															<div>
																{record?.area?.length
																	? record?.withArea?.map((vo, io) => (
																			<div key={io}>{vo}</div>
																	  ))
																	: "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Region
														</Col>
														<Col className="text" span={12}>
															<div>
																{record?.region?.length
																	? record?.region?.map((vo, io) => (
																			<div key={io}>{vo}</div>
																	  ))
																	: "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Related Games
														</Col>
														<Col className="text" span={12}>
															{GamesColumn("", record)}
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Basic Organization
														</Col>
														<Col className="text" span={12}>
															<>
																<div>
																	{record?.isBasic == 0
																		? "No"
																		: record?.isBasic == 1
																		? "Yes"
																		: ""}
																</div>
															</>
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Information
														</Col>
														<Col className="text" span={12}>
															<div className="Tabel_Information">
																{record?.__meta__?.exclusives_count >= 1 && (
																	<a
																		style={
																			record?.__meta__?.exclusives_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (
																				record?.__meta__?.exclusives_count >= 1
																			) {
																				history.push({
																					pathname: "/niko_knowledge_briefs",
																					state: { company: record?.id },
																				});
																			}
																		}}
																	>
																		Knowledge Brief
																	</a>
																)}

																{record?.__meta__?.InsightFeed_count >= 1 && (
																	<a
																		style={
																			record?.__meta__?.InsightFeed_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (
																				record?.__meta__?.InsightFeed_count >= 1
																			) {
																				history.push({
																					pathname: "/news_articles/VANA",
																					state: { company: record?.id },
																				});
																			}
																		}}
																	>
																		VANA
																	</a>
																)}

																{record?.__meta__?.Tournament_count >= 1 && (
																	<a
																		style={
																			record?.__meta__?.Tournament_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (
																				record?.__meta__?.Tournament_count >= 1
																			) {
																				history.push({
																					pathname: "/esports",
																					state: { sponsor: record?.id },
																				});
																			}
																		}}
																	>
																		Tournaments
																	</a>
																)}

																{record?.skipTeams?.length >= 1 && (
																	<a
																		style={
																			record?.skipTeams?.length >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.skipTeams?.length >= 1) {
																				history.push({
																					pathname: "/Esports/Teams",
																					state: { sponsor: record?.id },
																				});
																				window.scrollTo({
																					top: 0,
																					behavior: "smooth",
																				});
																			}
																		}}
																	>
																		Teams
																	</a>
																)}

																{record?.skipIpo?.length >= 1 && (
																	<a
																		style={
																			record?.skipIpo?.length >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.skipIpo?.length >= 1) {
																				history.push({
																					pathname: "/Financial/Transactions",
																					state: { companyId: record?.id },
																				});
																			}
																		}}
																	>
																		M&A
																	</a>
																)}
															</div>
														</Col>
													</Row>
												</p>
											</Panel>
										))}
									</Collapse>
								)}
							</Col>
						</Row>
						<Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
							<Col span={24}>
								{OrganizationMultiList?.error != 1009 &&
								OrganizationMultiList?.total
									? OrganizationMultiList?.total && (
											<Pagination
												current={
													parseInt(
														OrganizationMultiList && OrganizationMultiList?.page
													) || 1
												}
												total={
													(OrganizationMultiList &&
														OrganizationMultiList?.total) ||
													0
												}
												pageSize={
													OrganizationMultiList &&
													OrganizationMultiList?.perPage
												}
												onChange={onPageChange}
												showSizeChanger
												showQuickJumper
												showTotal={(total, range) =>
													`${range[0]}-${range[1]} of ${total} items`
												}
												locale={paginationLocale}
												itemRender={(page, type, originalElement) => {
													if (type === "page") {
														return <span>{page}</span>;
													}
													return originalElement;
												}}
												className="custom-pagination"
											/>
									  )
									: ""}
							</Col>
						</Row>
					</div>
				</Spin>
			</>
		</div>
	);
};
