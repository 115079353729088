import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Table,
	Spin,
	Tag,
	Collapse,
	Pagination,
	Select,
	Form,
	Typography,
	Image,
} from "antd";
import { effect, useStore, reducer } from "../../../utils/dva16";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
import { ETrend, NGame } from "../../../models/contants";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";
import defaultLogo from "../../../assets/images/company/defaul-logo_organization.png";
import { useHistory } from "react-router-dom";
import imgSource from "../../../assets";
import "../index2.less";
import TrendNotFound from "./TrendNotFound";
const { Option } = Select;
const { Text } = Typography;

export default (props) => {
	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const history = useHistory();
	const { trendingGames, loading } = useStore(NGame);
	const [searchValue, setSearchValue] = useState(null);
	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	function GamesColumn(text, record, index) {
		const showAll = showAllTags[index] || false;
		const games = record?.withGamesList ? [...record?.withGamesList] : [];
		const showCollapse = games?.length > 2;

		return (
			<div>
				{games
					?.slice(0, showAll ? record.withGamesList.length : 3)
					?.map((game, i) => (
						<Tag
							onClick={() => {
								toDetails(game?.id, true);
							}}
							className="greenTag"
							key={i}
							style={{ marginBottom: 4 }}
						>
							{game?.gameEn}
						</Tag>
					))}
				{(record?.withGamesList?.length > 3 ||
					record?.withCompanys?.length > 3 ||
					record?.withTeams?.length > 3 ||
					record?.withPlatforms?.length > 3 ||
					record?.genres?.length > 3) && (
					<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
						{showAll ? "Show less..." : "Show all..."}
					</Tag>
				)}
			</div>
		);
	}
	const [activeKey, setActiveKey] = useState([]);

	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};

	useEffect(() => {
		effect(NGame, ETrend, { page: 1, pageSize: 10 });
	}, []);

	const trendFilter = (value) => {
		effect(NGame, ETrend, {
			page: 1,
			pageSize: 10,
			trendFilter: value,
		});
	};

	const [showAllTags, setShowAllTags] = useState({});
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const [showAllTags2, setShowAllTags2] = useState({});
	const toggleShowAll2 = (index) => {
		setShowAllTags2((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};

	const columns = [
		{
			title: "Game",
			dataIndex: "gameEn",
			key: "gameEn",
			width: 250,
			align: "left",
			sortOrder: sortState?.field == "gameEn" && sortState?.order,
			sorter: (a, b) => a?.gameEn?.localeCompare(b?.gameEn),

			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a className="a_style" href={`#/games/details/${record?.id}`}>
									{record?.gameEn}
								</a>
							</p>
							<p>
								<a className="a_style" href={`#/games/details/${record?.id}`}>
									{record?.gameCn}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},
		{
			title: "Status",
			key: "status",
			dataIndex: "status",
			width: 100,
			align: "left",
			sortOrder: sortState?.field == "status" && sortState?.order,
			sorter: (a, b) => a?.status?.localeCompare(b?.status),
		},
		{
			title: "Genre",
			dataIndex: "genre",
			key: "genre",
			width: 150,
			align: "left",
			sortOrder: sortState?.field == "genre" && sortState?.order,
			sorter: (a, b) => {
				const genreA = a?.genre?.[0] || "";
				const genreB = b?.genre?.[0] || "";
				return genreA?.localeCompare(genreB);
			},
			render: (text, value, index) => (
				<>
					{value?.genre?.length > 0 &&
						value?.genre?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
		},

		{
			title: "Developer",
			dataIndex: "developer",
			key: "developer",
			className: "custom-publisher-column",
			align: "center",
			showSorterTooltip: false,
			width: 150,
			sortOrder: sortState?.field == "developer" && sortState?.order,
			sorter: (a, b) => {
				const developerA = a?.withDeveloper?.[0]?.name || "";
				const developerB = b?.withDeveloper?.[0]?.name || "";
				return developerA?.localeCompare(developerB);
			},

			render: (text, value, index) => {
				const showAll = showAllTags[index] || false;
				return (
					<div style={{ wordBreak: "break-all" }}>
						{value?.withDeveloper?.length > 0 &&
						Array.isArray(value?.withDeveloper)
							? value?.withDeveloper
									?.slice(0, showAll ? value.withDeveloper.length : 3)
									?.map((item, key) => {
										return (
											<Tag
												className="purbleTag"
												key={key}
												onClick={() => {
													toDetails(item?.id);
												}}
											>
												{item?.name}

												{key + 1 != value?.withDeveloper?.length ? "," : ""}
											</Tag>
										);
									})
							: value?.withDeveloper}

						{value?.withDeveloper?.length != 0 ? "" : "-"}
						{(value?.withLicensee?.length > 3 ||
							value?.withDeveloper?.length > 3 ||
							value?.withTeams?.length > 3 ||
							value?.withPlatforms?.length > 3 ||
							value?.genres?.length > 3) && (
							<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
								{showAll ? "Show less..." : "Show all..."}
							</Tag>
						)}
					</div>
				);
			},
		},
		{
			title: "Publisher",
			dataIndex: "publisher",
			key: "publisher",
			showSorterTooltip: false,
			align: "center",
			className: "custom-publisher-column",
			width: 150,
			sortOrder: sortState?.field == "publisher" && sortState?.order,
			sorter: (a, b) => {
				const publisherA = a?.withPublisher?.[0]?.name || "";
				const publisherB = b?.withPublisher?.[0]?.name || "";
				return publisherA?.localeCompare(publisherB);
			},
			render: (text, value, index) => {
				const showAll = showAllTags2[index] || false;
				return (
					<div style={{ wordBreak: "break-all" }}>
						{value?.withPublisher?.length > 0 &&
						Array.isArray(value?.withPublisher)
							? value?.withPublisher
									?.slice(0, showAll ? value.withPublisher.length : 3)
									?.map((item, key) => {
										return (
											<Tag
												className="purbleTag"
												key={key}
												onClick={() => {
													toDetails(item?.id);
												}}
											>
												{item?.name}
												{key + 1 != value?.withPublisher?.length ? "," : ""}
											</Tag>
										);
									})
							: value?.withPublisher}
						{value?.withPublisher?.length != 0 ? "" : "-"}
						{(value?.withPublisher?.length > 3 ||
							value?.withCompanys?.length > 3 ||
							value?.withTeams?.length > 3 ||
							value?.withPlatforms?.length > 3 ||
							value?.genres?.length > 3) && (
							<Tag className="greyTag" onClick={() => toggleShowAll2(index)}>
								{showAll ? "Show less..." : "Show all..."}
							</Tag>
						)}
					</div>
				);
			},
		},
		{
			title: "Platform",
			dataIndex: "platform",
			key: "platform",
			align: "left",
			showSorterTooltip: false,
			width: 100,
			sortOrder: sortState?.field == "platform" && sortState?.order,
			sorter: (a, b) => {
				const platformA = a?.platform?.[0] || "";
				const platformB = b?.platform?.[0] || "";
				return platformA?.localeCompare(platformB);
			},
			render: (text, value, index) => (
				<>
					{value?.platform?.length > 0 &&
						value?.platform?.map((item, index) => {
							return <div key={index}>{item + "\n"}</div>;
						})}
				</>
			),
		},
		{
			title: "No. of ISBN",
			key: "numOfIsbn",
			dataIndex: "numOfIsbn",
			width: 100,
			align: "left",
			sorter: (a, b) => (a?.numOfIsbn ?? 0) - (b?.numOfIsbn ?? 0),
			sortOrder: sortState?.field == "numOfIsbn" && sortState?.order,
		},
		{
			title: "Information",
			dataIndex: "Information",
			key: "Information",
			width: 150,
			align: "left",
			render: (text, record, index) => {
				return (
					<div className="Tabel_Information">
						{record?.exclusives_count >= 1 && (
							// record?.skipExclusives
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.exclusives_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.exclusives_count >= 1) {
										history.push({
											pathname: "/niko_knowledge_briefs",
											state: { game: record?.id },
										});
									}
								}}
							>
								Knowledge Brief
							</a>
						)}

						{record?.insightFeed_count >= 1 && (
							// record?.skipInsightFeed
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.insightFeed_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.insightFeed_count >= 1) {
										history.push({
											pathname: "/news_articles/VANA",
											state: { game: record?.id },
										});
									}
								}}
							>
								VANA
							</a>
						)}

						{record?.tournament_count >= 1 && (
							<a
								style={
									record?.tournament_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.tournament_count >= 1) {
										history.push({
											pathname: "/esports",
											state: { game: record?.id },
										});
									}
								}}
							>
								Tournaments
							</a>
						)}

						{record?.blogs_count >= 1 && (
							<a
								style={
									record?.blogs_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.blogs_count >= 1) {
										history.push({
											pathname: "/news_articles/blog_posts",
											state: { game: record?.id },
										});
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
									}
								}}
							>
								Blog Posts
							</a>
						)}
					</div>
				);
			},
		},
		{
			title: "% Change",
			dataIndex: "percentChange",
			showSorterTooltip: false,
			key: "percentChange",
			width: 300,
			align: "left",
			sortOrder: sortState?.field == "percentChange" && sortState?.order,
			// sorter: (a, b) => a?.percentChange?.localeCompare(b?.percentChange),
			render: (text, record, index) => (
				<>
					{record?.percentChange > 0 && (
						<div style={{ color: "#f4791f" }}>{record?.percentChange}%</div>
					)}
				</>
			),
		},
	];

	const toDetails = (value, isGame = false) => {
		if (isGame) {
			history.push(`/games/details/${value}`);
		} else {
			history.push(`/organizations/details/${value}`);
		}
	};

	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flexDirection: isMobile ? "column" : "row",
					}}
				>
					<p className="trendHeader">Trending Games</p>
					<div>
						<Select
							showArrow
							getPopupContainer={(triggerNode) => triggerNode.parentNode}
							placeholder={"Select"}
							onChange={trendFilter}
							className="homeSelect"
							defaultValue={"week"}
						>
							<Option value="week">Week-over-Week (Last 7 days)</Option>
							<Option value="month">Month-over-Month (Last 30 days)</Option>
							<Option value="year">Year-over-Year (Last 365 days)</Option>
						</Select>
						{trendingGames?.data?.length < 10 &&
							trendingGames?.data?.length != 0 && (
								<p className="textOnly">
									Only {trendingGames?.data?.length} games experienced
									significant changes in mentions.
								</p>
							)}
					</div>
				</div>
				<Spin spinning={loading} size="large">
					<div className="CompanyBox">
						<Row className="RowBox mt30" justify="right">
							<Col span={24}>
								{trendingGames?.data == 0 ? (
									<TrendNotFound module="game" />
								) : !isMobile ? (
									<Table
										showSorterTooltip={{
											target: "sorter-icon",
										}}
										scroll={{ x: "max-content" }}
										dataSource={trendingGames?.data || []}
										columns={columns}
										className="CompanyTableBox"
										pagination={false}
										onChange={(pagination, filters, sort) => {
											const pageSize = trendingGames?.perPage || 10; // Get the pageSize from your data or default
											const currentPage = trendingGames?.page || 1; // Get the
											setSortState(sort);
											// effect(NGame, ETrend, {
											// 	...searchValue,
											// 	perPage: pageSize,
											// 	page: currentPage,
											// 	// field: sort?.column && sort?.field,
											// 	// isDesc:
											// 	// 	(sort?.column &&
											// 	// 		sort?.order &&
											// 	// 		sortType[sort?.order]) ||
											// 	// 	null,
											// });
										}}
									/>
								) : (
									<Collapse
										accordion
										activeKey={activeKey}
										onChange={handleCollapseChange}
										className="MobileList"
										expandIconPosition="right"
										expandIcon={({ isActive }) => (
											<div className="custom-arrow">
												<img
													src={isActive ? reverseArrow : iconArrow}
													alt="arrow"
												/>
											</div>
										)}
									>
										{trendingGames?.data.map((record) => (
											<Panel
												header={
													<div className="panel-header">
														<div style={{ display: "flex" }}>
															{
																<img
																	src={record?.logo ? record.logo : defaultLogo} // Assuming item has an imageSrc property
																	alt="tournament"
																	className="tournament-image"
																/>
															}
															<a
																className="a_style"
																href={`#/games/details/${record?.id}`}
															>
																<div>
																	<div>{record?.gameEn}</div>
																	<div>{record?.gameCn}</div>
																</div>
															</a>
														</div>
													</div>
												}
												key={record?.id}
											>
												<p>
													<Row>
														<Col className="headCollapse" span={12}>
															Status
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																<div className="Tabel_Company">
																	<span>{record?.status || "-"}</span>
																</div>
															</>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Genre
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																{record?.genre?.length > 0 &&
																	record?.genre?.map((item, index) => {
																		return <div key={index}>{item + "\n"}</div>;
																	})}
															</>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Developer
														</Col>
														<Col span={12}>
															<div style={{ wordBreak: "break-all" }}>
																{record?.withDeveloper?.length > 0 &&
																Array.isArray(record?.withDeveloper)
																	? record?.withDeveloper?.map((item, key) => {
																			return (
																				<Tag
																					className="purbleTag"
																					key={key}
																					onClick={() => {
																						toDetails(item?.id);
																					}}
																				>
																					{item?.name}

																					{key + 1 !=
																					record?.withDeveloper?.length
																						? ","
																						: ""}
																				</Tag>
																			);
																	  })
																	: record?.withDeveloper}
																{record?.withDeveloper?.length != 0 ? "" : "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Publisher
														</Col>
														<Col className="textCollapse" span={12}>
															<div style={{ wordBreak: "break-all" }}>
																{record?.withPublisher?.length > 0 &&
																Array.isArray(record?.withPublisher)
																	? record?.withPublisher?.map((item, key) => {
																			return (
																				<Tag
																					className="purbleTag"
																					key={key}
																					onClick={() => {
																						toDetails(item?.id);
																					}}
																				>
																					{item?.name}
																					{key + 1 !=
																					record?.withPublisher?.length
																						? ","
																						: ""}
																				</Tag>
																			);
																	  })
																	: record?.withPublisher}
																{record?.withPublisher?.length != 0 ? "" : "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Platforms
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																{record?.platform?.length > 0 &&
																	record?.platform?.map((item, index) => {
																		return <div key={index}>{item + "\n"}</div>;
																	})}
															</>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															No. of ISBN
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																<div className="Tabel_Company">
																	<span>{record?.numOfIsbn || "-"}</span>
																</div>
															</>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															Information
														</Col>
														<Col className="textCollapse" span={12}>
															<div className="Tabel_Information">
																{record?.exclusives_count >= 1 && (
																	<a
																		style={
																			record?.exclusives_count >= 1
																				? { margin: "5px" }
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																						margin: "5px",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.exclusives_count >= 1) {
																				history.push({
																					pathname: "/niko_knowledge_briefs",
																					state: { game: record?.id },
																				});
																			}
																		}}
																	>
																		Knowledge Brief
																	</a>
																)}

																{record?.InsightFeed_count >= 1 && (
																	<a
																		style={
																			record?.InsightFeed_count >= 1
																				? { margin: "5px" }
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																						margin: "5px",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.InsightFeed_count >= 1) {
																				history.push({
																					pathname: "/news_articles/VANA",
																					state: { game: record?.id },
																				});
																			}
																		}}
																	>
																		VANA
																	</a>
																)}

																{record?.Tournament_count >= 1 && (
																	<a
																		style={
																			record?.Tournament_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.Tournament_count >= 1) {
																				history.push({
																					pathname: "/Esports",
																					state: { game: record?.id },
																				});
																				// Scroll to the top of the page
																				window.scrollTo({
																					top: 0,
																					behavior: "smooth",
																				});
																			}
																		}}
																	>
																		Tournaments
																	</a>
																)}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="headCollapse" span={12}>
															% Change
														</Col>
														<Col className="textCollapse" span={12}>
															<>
																<div className="Tabel_Company">
																	<span style={{ color: "#f4791f" }}>
																		{record?.percentChange}%{" "}
																		{record?.percentChange == 100 &&
																			"(New mention)"}
																	</span>
																</div>
															</>
														</Col>
													</Row>
												</p>
											</Panel>
										))}
									</Collapse>
								)}
							</Col>
						</Row>
						{/* <Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
							<Col span={24}>
								{trendingGames?.error != 1009 && trendingGames?.total
									? trendingGames?.total && (
											<Pagination
												current={parseInt(trendingGames && trendingGames?.page) || 1}
												total={(trendingGames && trendingGames?.total) || 0}
												pageSize={trendingGames && trendingGames?.perPage}
												onChange={onPageChange}
												showSizeChanger
												showQuickJumper
												showTotal={(total, range) =>
													`${range[0]}-${range[1]} of ${total} items`
												}
												locale={paginationLocale}
												itemRender={(page, type, originalElement) => {
													if (type === "page") {
														return <span>{page}</span>;
													}
													return originalElement;
												}}
												className="custom-pagination"
											/>
									  )
									: ""}
							</Col>
						</Row> */}
					</div>
				</Spin>
			</>
		</div>
	);
};
